"use client";

import React from "react";
import { useTranslations } from "next-intl";
import FaqCompactSection from "./FaqCompactSection";

type FaqTranslationKeys = Messages["FAQ"]["general_faq"];

function ClientFaq() {
  const t = useTranslations("FAQ.general_faq");
  const faqIds = [1, 2, 3, 4, 5];
  const faqItems = faqIds.map((id) => ({
    slug: t(`slug_${id}` as keyof FaqTranslationKeys),
    question: t(`question_${id}` as keyof FaqTranslationKeys),
    answer: t.raw(`answer_${id}` as keyof FaqTranslationKeys)
  }));

  return <FaqCompactSection faq={faqItems} />;
}

export { ClientFaq };
