"use client";
import { pushDataLayerEvent } from "@/analytics/analytics.datalayer";
import { ButtonV2 } from "@/design-system/components/button/ButtonV2";
import React from "react";
import { useTranslations } from "next-intl";
import { GetMatchedClickedSource } from "@/analytics/analytics.directory";
import { Link } from "@/i18n/routing";
import { directory } from "@/analytics";

type GetMatchedCTAProps = {
  clickSource: GetMatchedClickedSource;
  ctaText?: string; // Optional prop for custom CTA text
  solidLayout?: boolean;
  destination: "matching_survey" | "matching_landing_page";
} & React.ComponentProps<typeof ButtonV2>;

const GetMatchedCTA = React.forwardRef(
  (
    {
      clickSource,
      ctaText,
      destination,
      solidLayout = false,
      ...props
    }: GetMatchedCTAProps,
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    const t = useTranslations("Common");
    // Use ctaText if provided, otherwise fall back to the default translation
    const buttonText = ctaText || t("find_your_match");

    return (
      <ButtonV2
        ref={ref}
        variant={solidLayout ? "solid" : "outline"}
        size={"3"}
        {...props}
        asChild
      >
        <Link
          onClick={() => {
            directory.getMatchedClicked({ source: clickSource });
            pushDataLayerEvent("get_matched_clicked");
          }}
          style={{ cursor: "pointer" }}
          href={
            destination === "matching_survey" ? "/matching/survey" : "/matching"
          }
        >
          {buttonText}
        </Link>
      </ButtonV2>
    );
  },
);

GetMatchedCTA.displayName = "SearchGetMatched";

export { GetMatchedCTA };
