import { useTranslations } from "next-intl";
import { Flex } from "@radix-ui/themes";
import { ButtonV2 } from "@/design-system/components/button/ButtonV2";
import Link from "next/link";
import React from "react";

function GoToHelpCenterCta({
  isGreenLayout = false,
}: {
  isGreenLayout?: boolean;
}) {
  const t = useTranslations("FAQ");
  return (
    <Flex direction={"column"} align={"center"}>
      <ButtonV2
        variant={"outline"}
        color={isGreenLayout ? "mint" : "gray"}
        size={"3"}
        asChild
      >
        <Link href={t("go_to_help_center_link")}>{t("go_to_help_center")}</Link>
      </ButtonV2>
    </Flex>
  );
}

export { GoToHelpCenterCta };
