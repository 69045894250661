import { FrequentlyAskedQuestion } from "@/api/api.directory.faq";
import { LayoutContainerV2 } from "@/design-system/components/layout-container/LayoutContainerV2";
import { HeadingV2 } from "@/design-system/components/text/HeadingV2";
import { Flex } from "@radix-ui/themes";
import { useTranslations } from "next-intl";
import React from "react";
import { FaqCompactSections } from "../Faq/FaqSection";
import { GoToHelpCenterCta } from "../Faq/GoToHelpCenterCta";

function FaqCompactSection({
  faq,
}: {
  faq: FrequentlyAskedQuestion[] | undefined;
}) {
  const t = useTranslations("FAQ");

  return (
    faq &&
    faq.length > 0 && (
      <LayoutContainerV2 size="2" py={"9"}>
        <Flex
          direction={"column"}
          gap={{ initial: "6", sm: "9" }}
          align={"center"}
        >
          <HeadingV2 textStyle={"Headline L"} align={"center"} as={"h2"}>
            {t("title")}
          </HeadingV2>
          <FaqCompactSections faq={faq} underlineLayout={true} />
          <GoToHelpCenterCta isGreenLayout={true} />
        </Flex>
      </LayoutContainerV2>
    )
  );
}

export default FaqCompactSection;
