"use client";
import { useTranslations } from "next-intl";
import React from "react";
import { Card, Flex } from "@radix-ui/themes";
import { TextV2 } from "@/design-system/components/text/TextV2";
import { GetMatchedCTA } from "@components/Search/GetMatched/GetMatchedCTA";

function SearchFallback() {
  const t = useTranslations("Search.fallback");
  return (
    <Flex align={"center"} justify={"center"} p={"5"}>
      <Card
        variant={"ghost"}
        style={{ backgroundColor: "var(--colorV2-grey-light" }}
      >
        <Flex
          gap={"5"}
          p={"5"}
          direction={"column"}
          align={"center"}
          style={{ maxWidth: "600px" }}
        >
          <TextV2 textStyle={"Headline S"} align={"center"}>
            {t("title")}
          </TextV2>
          <TextV2 align={"center"} textStyle={"Body M"}>
            {t("subtitle")}
          </TextV2>
          <GetMatchedCTA
            clickSource={"no_search_results"}
            destination={"matching_survey"}
          />
        </Flex>
      </Card>
    </Flex>
  );
}

export { SearchFallback };
